import {
  Alarm as AlarmIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  ListAlt as ListAltIcon,
  SubdirectoryArrowRight as SubdirectoryArrowRightIcon
} from '@mui/icons-material';
import { Box, Collapse, Divider, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { compose } from 'redux';
import {
  CarePlanAutocomplete,
  CarePlanAutocompletePaper,
  CarePlanAutocompletePopper,
  CarePlanAutocompleteTextField as CarePlanTextField
} from '../../components/CarePlanAutocomplete/CarePlanAutocomplete';
import { Text } from '../../components/Typography/Text';
import { getCarePlanByIdSelector, getCarePlanStepByIdSelector, getCarePlanUnitByIdSelector, getTriggerByIdSelector } from '../../redux/carePlan/carePlanSelectors';
import { fetchCarePlansThunk } from '../../redux/carePlan/carePlanThunks';
import { CarePlan } from '../../redux/carePlan/carePlanTypes';
import { getCarePlanDeviceContentByIdSelector } from '../../redux/content/contentSelectors';
import { ReduxState } from '../../redux/types';
import { Color } from '../../utils/color';
import style from './ManageGroupPlans.scss';
import NavTopBarContentWrapper from '../../components/NavTopBarContentWrapper/NavTopBarContentWrapper';

type PropsFromRedux = ConnectedProps<typeof connectRedux>;
type Props = PropsFromRedux & {};

const ManageGroupPlans: React.FC<Props> = ({ carePlanById, carePlanStepById, carePlanUnitById, carePlanDeviceContentById, triggerById, fetchCarePlans }) => {
  const [selectedPlanId, setSelectedPlanId] = useState<string>();
  const [selectedPlan, setSelectedPlan] = useState<CarePlan | null>(null);
  const [openStepIds, setOpenStepIds] = useState(new Set());

  const handleToggle = (stepId) => {
    setOpenStepIds((prevOpenStepIds) => {
      const newOpenStepIds = new Set(prevOpenStepIds);
      if (newOpenStepIds.has(stepId)) {
        newOpenStepIds.delete(stepId);
      } else {
        newOpenStepIds.add(stepId);
      }
      return newOpenStepIds;
    });
  };

  const sortedPlanOptions = React.useMemo(() => {
    const plans: CarePlan[] = Object.values(carePlanById);
    return plans.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
  }, [carePlanById]);

  useEffect(() => {
    fetchCarePlans();
  }, []);

  const handlePlanChange = (event, value) => {
    setSelectedPlanId(value?.id);
    setSelectedPlan(value);
  };

  const selectedCarePlan = React.useMemo(() => (carePlanById && selectedPlanId ? carePlanById[selectedPlanId] : undefined), [carePlanById, selectedPlanId]);

  return (
    <NavTopBarContentWrapper headerText="Manage care plans">
      <div className={style.autocompleteBox}>
        <div>
          <Text variant="sm" className={style.labelSelect}>
            Care plan
          </Text>
          <CarePlanAutocomplete
            options={sortedPlanOptions}
            value={selectedPlan || null}
            onChange={handlePlanChange}
            getOptionLabel={(option) => (option as CarePlan).name || ''} // display only the full name as label
            isOptionEqualToValue={(option, value) => (option as CarePlan).id === (value as CarePlan).id} // use id for comparison
            PaperComponent={CarePlanAutocompletePaper}
            PopperComponent={CarePlanAutocompletePopper}
            renderInput={(params) => <CarePlanTextField {...params} placeholder="Select" />}
            renderOption={(props, option, state) => (
              <li {...props} key={(option as CarePlan).id}>
                <Box>{(option as CarePlan).name}</Box>
              </li>
            )}
          />
        </div>
      </div>
      {!!selectedPlanId && (
        <Box className={style.infoBox}>
          <Typography variant="h5" component="h1" gutterBottom>
            Care Plan Steps:
          </Typography>
          <List>
            {!!selectedCarePlan?.carePlanStepsIds?.length &&
              selectedCarePlan.carePlanStepsIds.map((stepId, index) => {
                const step = carePlanStepById[stepId];
                const unit = step?.carePlanUnitId ? carePlanUnitById[step?.carePlanUnitId] : undefined;
                const trigger = triggerById[step?.triggerId];
                const deviceContent = unit?.deviceContentId ? carePlanDeviceContentById[unit?.deviceContentId] : undefined;
                const isOpen = openStepIds.has(stepId);

                return (
                  <React.Fragment key={stepId}>
                    <ListItem onClick={() => handleToggle(stepId)}>
                      <ListItemText primary={unit?.name?.toUpperCase()} />
                      {!!trigger && (
                        <ListItemIcon>
                          <AlarmIcon />
                        </ListItemIcon>
                      )}
                      {isOpen ? <ExpandLessIcon sx={{ color: Color.DARK_BLUE }} /> : <ExpandMoreIcon sx={{ color: Color.DARK_BLUE }} />}
                    </ListItem>
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {!!trigger && (
                          <ListItem sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <AlarmIcon />
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="body1">Trigger: {trigger?.afterTime}</Typography>} />
                          </ListItem>
                        )}
                        <ListItem sx={{ pl: 4 }}>
                          <ListItemIcon>
                            <ListAltIcon />
                          </ListItemIcon>
                          <ListItemText primary={<Typography variant="body1">Unit Text: {deviceContent?.contentText || ''}</Typography>} />
                        </ListItem>
                        {!!deviceContent?.deviceContentPages?.length &&
                          deviceContent.deviceContentPages.map((contentPage, subIndex) => (
                            <React.Fragment key={subIndex}>
                              <ListItem sx={{ pl: 8 }}>
                                <ListItemIcon>
                                  <SubdirectoryArrowRightIcon />
                                </ListItemIcon>
                                <ListItemText primary={<Typography variant="body1">Unit Page Text: {contentPage?.longText || ''}</Typography>} />
                              </ListItem>
                              <ListItem key={subIndex} sx={{ pl: 18 }}>
                                <ListItemText primary={<Typography variant="body1">Question: {contentPage?.surveyQuestion?.questionText || ''}</Typography>} />
                              </ListItem>
                            </React.Fragment>
                          ))}
                      </List>
                    </Collapse>
                    {index !== selectedCarePlan?.carePlanStepsIds.length - 1 && <Divider component="li" />}
                  </React.Fragment>
                );
              })}
          </List>
        </Box>
      )}
    </NavTopBarContentWrapper>
  );
};

const connectRedux = connect(
  (state: ReduxState) => ({
    carePlanById: getCarePlanByIdSelector(state),
    carePlanStepById: getCarePlanStepByIdSelector(state),
    carePlanUnitById: getCarePlanUnitByIdSelector(state),
    carePlanDeviceContentById: getCarePlanDeviceContentByIdSelector(state),
    triggerById: getTriggerByIdSelector(state)
  }),
  { fetchCarePlans: fetchCarePlansThunk }
);

export default compose(connectRedux)(ManageGroupPlans);
