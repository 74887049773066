import React from 'react';
import style from './VitalCard.scss';
import { Text } from '../../../../components/Typography';
import { VitalSignsStatus } from '../../../../redux/person/personTypes';
import { VitalSign } from '../../../../components/VitalSign/VitalSign';

export type VitalCardProps = {
  vitalTitle?: string;
  vitalSubtitle?: string;
  vitalValue?: string | number;
  vitalUnit?: string;
  imgSrc?: string;
  vitalStatus?: VitalSignsStatus;
  vitalStatusText?: string;
};

const getVitalTextClassName = (status?: VitalSignsStatus) => {
  switch (status) {
    case VitalSignsStatus.ABNORMAL:
      return style.redText;
    case VitalSignsStatus.SUBOPTIMAL:
      return style.yellowText;
    default:
      return null;
  }
};

export const VitalCard: React.FC<VitalCardProps> = ({ vitalTitle, vitalSubtitle, vitalValue, vitalUnit = 'BPM', imgSrc, vitalStatus, vitalStatusText = '' }) => {
  return (
    <div className={style.card}>
      <div className={style.cardTitleContainer}>
        <span className={style.defaultText}>{vitalTitle}</span>
        <span className={style.defaultGrayText}>{vitalSubtitle}</span>
      </div>
      <div className={style.vitalContainer}>
        <div>
          <img src={imgSrc} className={style.smallIcon} />
        </div>
        <div className={style.vitalValueContainer}>
          <Text className={style.vitalValueText}>{vitalValue ? vitalValue : '-'}</Text>
        </div>
        <div className={style.vitalValueContainer}>
          <Text className={style.vitalValueUnit}>{vitalUnit}</Text>
        </div>
      </div>
      <VitalSign status={vitalStatus} showBg textValue={vitalStatusText} textClassName={getVitalTextClassName(vitalStatus)} />
    </div>
  );
};
