import React from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import style from './DropDownMenu.scss';
import classNames from 'classnames';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

export type DropDownMenuProps = {
  menuText?: string;
  customMenuElement?: (onMenuClick: (event) => void) => React.JSX.Element;
  menuItems: MenuItemType[];
};

export type MenuItemType = MenuItemProps | false;

export type CustomMenuItem = (event) => React.JSX.Element;

export type MenuItemProps = {
  onClick?: () => void;
  text?: string;
  textClassName?: string;
  iconImgSrc?: string;
  customMenuItem?: () => React.JSX.Element;
};

export const DropDownMenu: React.FC<DropDownMenuProps> = React.memo(({ menuText, customMenuElement, menuItems = [] }) => {
  const [menuAnchorElement, setMenuAnchorElement] = React.useState(null);
  const isMenuOpen = Boolean(menuAnchorElement);

  const onMenuClick = (event) => {
    setMenuAnchorElement(event.currentTarget);
  };

  const onMenuClose = () => {
    setMenuAnchorElement(null);
  };
  const filteredMenuItems = menuItems.filter(Boolean) as MenuItemProps[];

  const getMenuItem = (menuItem: MenuItemProps, index): React.JSX.Element => {
    let menuItemElement;

    if (menuItem.customMenuItem) {
      menuItemElement = menuItem.customMenuItem();
    } else if (menuItem.iconImgSrc) {
      menuItemElement = (
        <>
          <img src={menuItem.iconImgSrc} className={style.menuItemIcon} />
          <div className={classNames(style.menuItemText, menuItem.textClassName)}>{menuItem.text}</div>
        </>
      );
    } else {
      menuItemElement = <div className={classNames(style.menuItemText, menuItem.textClassName)}>{menuItem.text}</div>;
    }

    return (
      <MenuItem
        key={index}
        onClick={() => {
          menuItem.onClick?.();
          onMenuClose();
        }}
      >
        {menuItemElement}
      </MenuItem>
    );
  };

  return (
    <div>
      {!customMenuElement && (
        <Button className={classNames(style.tab, isMenuOpen ? style.active : {})} onClick={onMenuClick} endIcon={isMenuOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}>
          {menuText}
        </Button>
      )}
      {customMenuElement && customMenuElement(onMenuClick)}
      <Menu anchorEl={menuAnchorElement} open={isMenuOpen} onClose={onMenuClose} className={style.menu}>
        {filteredMenuItems.map((menuItem, index) => getMenuItem(menuItem, index))}
      </Menu>
    </div>
  );
});
