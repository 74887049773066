import React, { Dispatch, SetStateAction } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, PropTypes, TextField } from '@mui/material';

interface Props {
  open: boolean;
  titleText?: Nullable<string>;
  dialogText: string;
  inputSetter?: Nullable<Dispatch<SetStateAction<string>>>;
  proceedEvent?: Nullable<(e) => void>;
  proceedEventText?: Nullable<string>;
  proceedEventTextColor?: Nullable<'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'>;
  closeEvent: (e) => void;
  closeEventText?: Nullable<string>;
}

export const ConfirmationDialogV2: React.FC<Props> = ({
  open,
  titleText,
  dialogText,
  inputSetter,
  proceedEventText,
  proceedEventTextColor,
  proceedEvent,
  closeEvent,
  closeEventText
}) => {
  return (
    <Dialog open={open} onClose={(e) => closeEvent(e)}>
      {!!titleText && <DialogTitle>{titleText}</DialogTitle>}
      <DialogContent>
        <DialogContentText style={{ textDecoration: 'underline' }}>{dialogText}</DialogContentText>
        {inputSetter && <TextField autoFocus margin="dense" fullWidth onChange={(e) => inputSetter(e.target.value)} />}
      </DialogContent>
      <DialogActions>
        {proceedEvent && (
          <Button onClick={(e) => proceedEvent(e)} color={proceedEventTextColor ? proceedEventTextColor : 'primary'}>
            {proceedEventText ? proceedEventText : 'Yes'}
          </Button>
        )}
        <Button onClick={(e) => closeEvent(e)} color="primary">
          {closeEventText ? closeEventText : 'Cancel'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
