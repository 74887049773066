import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { CloseIconDialog } from '../../components/CloseIconDialog/CloseIconDialog';
import { ReduxState } from '../../redux/types';
import { compose } from 'redux';
import { Text } from '../../components/Typography';
import style from './ViewPerson.scss';
import { getPersonByIdThunk } from '../../redux/person/personThunks';
import { getPersonByIdSelector } from '../../redux/person/personSelector';
import { getInitials } from '../../utils/util';
import { Grid } from '@mui/material';
import { normalizeEnumName } from '../../utils/content';
import { Location as PatientLocation } from '../../redux/device/deviceTypes';

export type ViewPersonProps = {
  personId: string;
  showDialog: boolean;
  onDialogClose: () => void;
};

type PropsFromRedux = ConnectedProps<typeof connectRedux>;
type Props = PropsFromRedux & ViewPersonProps;

const ViewPerson: React.FC<Props> = ({ personId, showDialog, onDialogClose, personById, fetchPersonById }) => {
  const [showViewPerson, setShowViewPerson] = React.useState(showDialog);

  const person = personById[personId];
  const personName = person?.fullName ?? '';
  const email = person?.email ?? '';
  const personInitial = getInitials(personName);

  const closeDialog = () => {
    setShowViewPerson(false);
    onDialogClose?.();
  };

  useEffect(() => {
    setShowViewPerson(showDialog);
  }, [showDialog]);

  useEffect(() => {
    if (showViewPerson) {
      fetchPersonById(personId);
    }
  }, [personId, showViewPerson]);

  const getAddressLine = (address: PatientLocation) => {
    return [address.street, address.streetSecondLine, address.city, address.state, address.postalCode].filter(Boolean).join(', ');
  };

  const fieldValues = [
    {
      'Date of birth': person?.birthDate ?? '-',
      'Phone number': person?.phoneNumber ?? '-'
    },
    {
      Gender: normalizeEnumName(person?.genderType) ?? '-',
      Pronoun: normalizeEnumName(person?.pronounType) ?? '-'
    },
    {
      Address: person?.shippingAddress ? getAddressLine(person?.shippingAddress) : '-',
      Nickname: person?.nickname ?? '-'
    }
  ];

  const getViewElement = () => {
    return (
      <div className={style.topContainer}>
        <div className={style.avatarAndText}>
          <div className={style.avatar}>{personInitial}</div>
          <Text variant="md">{personName}</Text>
          <Text variant="sm" className={style.grayText}>
            {email}
          </Text>
        </div>
        <div className={style.patientDetail}>
          <Text variant="lg">Patient details</Text>
        </div>

        <Grid container>
          {fieldValues.map((fieldValue, index) => (
            <>
              <Grid container key={index}>
                {Object.keys(fieldValue).map((label, index) => (
                  <Grid item xs={6} key={index}>
                    <Text variant="sm">{label}</Text>
                  </Grid>
                ))}
              </Grid>
              <Grid container key={index} className={style.fieldValue}>
                {Object.values(fieldValue).map((value, index) => (
                  <Grid item xs={6} key={index}>
                    <Text variant="sm" className={style.grayText}>
                      {value}
                    </Text>
                  </Grid>
                ))}
              </Grid>
            </>
          ))}
        </Grid>
      </div>
    );
  };

  return <div>{showViewPerson && <CloseIconDialog open={showViewPerson} onClose={closeDialog} getContent={getViewElement} />}</div>;
};

const connectRedux = connect(
  (state: ReduxState) => (state: ReduxState) => ({
    personById: getPersonByIdSelector(state)
  }),
  {
    fetchPersonById: getPersonByIdThunk
  }
);

export default compose(connectRedux)(ViewPerson);
