import React, { useEffect, useState } from 'react';
import style from './PatientDashboard.scss';
import { connect, ConnectedProps } from 'react-redux';
import { ReduxState } from '../../redux/types';
import { getSelectedPersonVitalOverview } from '../../redux/person/personSelector';
import { getPersonVitalOverviewThunk } from '../../redux/person/personThunks';
import { compose } from 'redux';
import { useNavigate, useParams } from 'react-router';
import { getInitials } from 'utils/util';
import { Text } from '../../components/Typography';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { DropDownMenu } from 'components/DropDownMenu/DropDownMenu';
import heartBeatIcon from '../../assets/images/heartBeat.png';
import breathingRateIcon from '../../assets/images/breathingRate.png';
import morningStarIcon from '../../assets/images/morningStar.png';
import chartLineIcon from '../../assets/images/chartLine.png';
import { VitalCard } from './components/VitalCard/VitalCard';
import { ComingSoon } from './components/ComingSoon/ComingSoon';
import Insights from './components/Insights/Insights';
import Feed from '../../containers/PatientProfile/Feed/Feed';
import HorizontalTabs from '../../components/HorizontalTabs/HorizontalTabs';
import { getDeviceTimeZoneIdSelector } from '../../redux/device/deviceSelectors';
import { DEFAULT_TIMEZONE_ID, getElapsedTimeDisplay } from '../../utils/time';
import NavTopBarContentWrapper from '../../components/NavTopBarContentWrapper/NavTopBarContentWrapper';
import { normalizeEnumName } from 'utils/content';
import DeletePatient from './components/DeletePatient/DeletePatient';
import ViewPerson from '../ViewPerson/ViewPerson';
import { getFixedPointDisplay } from '../../utils/util';
import { getVitalStatusOutOfRangeText } from './PatientDashboardUtil';

type PropsFromRedux = ConnectedProps<typeof connectRedux>;
type Props = PropsFromRedux & {};

const PatientDashboard: React.FC<Props> = ({ getDeviceTimeZoneId, selectedPersonVitalOverview, getPersonVitalOverview }) => {
  const params = useParams();
  const patientId = params?.userId ?? '';
  const navigate = useNavigate();

  const personName = selectedPersonVitalOverview?.personName ?? '';
  const personInitial = getInitials(personName);
  const timeZoneId = getDeviceTimeZoneId(patientId) || DEFAULT_TIMEZONE_ID;
  const [showDeletePatientConfirmation, setShowDeletePatientConfirmation] = useState(false);
  const [showViewPatient, setShowViewPatient] = useState(false);

  useEffect(() => {
    getPersonVitalOverview(patientId);
  }, [patientId]);

  const onAddActivityClick = () => {
    navigate(`/patients/${patientId}/newActivity`);
  };

  const onViewPatientDetailClick = () => {
    setShowViewPatient(true);
  };

  const onEditPatientClick = () => {
    navigate(`/patients/${patientId}/editPatient`);
  };

  const onDeletePatientClick = () => {
    console.log('onDeletePatientClick');
    setShowDeletePatientConfirmation(true);
  };

  const tabs = [
    { label: 'Insights', content: <Insights personId={patientId} timeZoneId={timeZoneId} /> },
    { label: 'Activity feed', content: <Feed /> }
  ];

  return (
    <NavTopBarContentWrapper>
      <div className={style.mainContainer}>
        {/* Patient Details and Activity */}
        <div className={style.patientDetailSection}>
          <div className={style.breadcrumbs}>
            <span className={style.breadCrumText}>Patients</span>
            <div className={style.slashDivider} />
            <span className={style.breadCrumTextActive}>{personName}</span>
          </div>
          <div className={style.avatarDeviceContainer}>
            <div className={style.avatarAndText}>
              <div className={style.avatar}>{personInitial}</div>
              <Text variant="xl" className={style.fullNameText}>
                {selectedPersonVitalOverview?.personName}
              </Text>
            </div>
            <div className={style.patientPresenceTopContainer}>
              <div className={style.deviceIconContainer}>
                <div className={style.deviceIcon} />
              </div>
              <div className={style.presenceContainer}>
                <span className={style.patientPresence}>Patient presence</span>
                {selectedPersonVitalOverview?.personPresence && selectedPersonVitalOverview?.personPresenceLastReading && (
                  <span className={style.nearTheDeviceSinceMAgo}>
                    {normalizeEnumName(selectedPersonVitalOverview?.personPresence)} &middot; {getElapsedTimeDisplay(selectedPersonVitalOverview?.personPresenceLastReading)}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className={style.actionsContainer}>
            <Button variant="contained" color="primary" onClick={onAddActivityClick} startIcon={<AddIcon className={style.addIcon} />}>
              Add activity
            </Button>
            <DropDownMenu
              menuText="Actions"
              menuItems={[
                {
                  text: 'View patient detail',
                  onClick: onViewPatientDetailClick
                },
                {
                  text: 'Edit',
                  onClick: onEditPatientClick
                },
                {
                  text: 'Delete',
                  textClassName: style.redText,
                  onClick: onDeletePatientClick
                }
              ]}
            />
          </div>
          <DeletePatient patientName={personName} patientId={patientId} onDialogClose={() => setShowDeletePatientConfirmation(false)} showDialog={showDeletePatientConfirmation} />
          <ViewPerson personId={patientId} showDialog={showViewPatient} onDialogClose={() => setShowViewPatient(false)} />
        </div>
        <div className={style.overviewTextContainer}>
          <Text variant="sm" className={style.defaultText}>
            Overview for today
          </Text>
        </div>

        <div className={style.cardsContainer}>
          <VitalCard
            vitalTitle="Heart rate"
            vitalSubtitle={selectedPersonVitalOverview?.heartRateLastReading ? getElapsedTimeDisplay(selectedPersonVitalOverview?.heartRateLastReading) : ''}
            vitalValue={getFixedPointDisplay(selectedPersonVitalOverview?.heartRate)}
            imgSrc={heartBeatIcon}
            vitalStatus={selectedPersonVitalOverview?.heartRateStatus}
            vitalStatusText={getVitalStatusOutOfRangeText(selectedPersonVitalOverview?.heartRateStatus)}
          />
          <VitalCard
            vitalTitle="Breathing rate"
            vitalSubtitle={selectedPersonVitalOverview?.breathingRateLastReading ? getElapsedTimeDisplay(selectedPersonVitalOverview?.breathingRateLastReading) : ''}
            vitalValue={getFixedPointDisplay(selectedPersonVitalOverview?.breathingRate)}
            imgSrc={breathingRateIcon}
            vitalStatus={selectedPersonVitalOverview?.breathingRateStatus}
            vitalStatusText={getVitalStatusOutOfRangeText(selectedPersonVitalOverview?.breathingRateStatus)}
          />
          <ComingSoon title="Sleep efficiency" imgSrc={morningStarIcon} />
          <div className={style.sleepCardsContainer}>
            <ComingSoon title="Awakenings" imgSrc={chartLineIcon} />
            <ComingSoon title="Total sleep" imgSrc={chartLineIcon} />
          </div>
        </div>

        <HorizontalTabs tabs={tabs} buttonClassName={style.HorizontalTabsButton} tabsContentClassname={style.tabsContent} />
      </div>
    </NavTopBarContentWrapper>
  );
};

const connectRedux = connect(
  (state: ReduxState) => ({
    getDeviceTimeZoneId: (personId: string) => getDeviceTimeZoneIdSelector(personId, state),
    selectedPersonVitalOverview: getSelectedPersonVitalOverview(state)
  }),
  {
    getPersonVitalOverview: getPersonVitalOverviewThunk
  }
);

export default compose(connectRedux)(PatientDashboard);
