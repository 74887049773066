import React, { useState } from 'react';
import style from './EnumSelect.scss';
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';

export type EnumSelectProps = {
  enumData: { [key in string]: string };
  initialSelection?: string;
  label?: string;
  onSelectionChange?: (selected: string) => void;
  fullWidth?: boolean;
};

export const EnumSelect: React.FC<EnumSelectProps> = ({ enumData, initialSelection = '', label, onSelectionChange, fullWidth = true }) => {
  const [current, setCurrent] = useState(initialSelection);
  const enumKeys = getEnumKeys(enumData);

  const onChangeEvent = (e: SelectChangeEvent) => {
    setCurrent(e.target.value);
    onSelectionChange && onSelectionChange(e.target.value);
  };

  return (
    <div className={style.topContainer}>
      <FormControl fullWidth={fullWidth}>
        <Select value={current} label={label} onChange={onChangeEvent}>
          {enumKeys.map((key, index) => (
            <MenuItem key={index} value={enumData[key]}>
              {enumData[key]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

function getEnumKeys<TEnumKey extends string, TEnumValue extends string | number>(enumInput: { [key in TEnumKey]: TEnumValue }) {
  return Object.keys(enumInput) as Array<TEnumKey>;
}
