import React from 'react';
import { Text } from '../../../../components/Typography';
import style from './TableCell.scss';
import { getFixedPointDisplay, getInitials, properCase } from '../../../../utils/util';
import { PatientTableColName } from './PatientTableTypes';
import { getElapsedTimeDisplay } from 'utils/time';
import { TextVariant } from '../../../../components/TextField/TextField';
import darkGreenDot from '../../../../assets/images/darkGreenDot.png';
import grayDot from '../../../../assets/images/grayDot.png';
import { VitalSignsStatus } from '../../../../redux/person/personTypes';
import { VitalSign } from '../../../../components/VitalSign/VitalSign';

type CellPropT = string | number;

export type PatientTableCellProp<T extends CellPropT> = {
  value?: T;
  colName: PatientTableColName;
  avatarUrl?: string;
  modifiedDate?: string;
  status?: VitalSignsStatus;
};

enum PatientPresenceType {
  NEAR_THE_DEVICE = 'Near the device',
  OUT_OF_LUNA_VISION = "Out of Luna's vision"
}

const getPatientPresence = (patientPresence: string): PatientPresenceType => {
  const nearDevicePresences = ['BED', 'BEDSIDE_NEAR', 'BEDSIDE_FAR', 'BEDROOM'];
  return nearDevicePresences.includes(patientPresence) ? PatientPresenceType.NEAR_THE_DEVICE : PatientPresenceType.OUT_OF_LUNA_VISION;
};

const getDisplayValueOrDefault = (value: CellPropT | undefined, defaultValue = '-', postfix = ''): string => {
  if (value === null || value === undefined) {
    return defaultValue;
  }
  if (typeof value === 'string') {
    return value + postfix;
  }
  return '' + getFixedPointDisplay(value) + postfix;
};

type ValueTextElementProps = {
  value: string;
  cellTextClassName?: string;
  textVariant?: TextVariant;
};
const ValueTextElement = ({ value = '', cellTextClassName = style.cellText, textVariant = 'sm' }: ValueTextElementProps) => {
  return (
    <Text variant={textVariant} className={cellTextClassName}>
      {value}
    </Text>
  );
};

const getDeviceStatusStyleClassName = (value: string) => {
  const capitalized = value.toUpperCase();
  if (['ACTIVE', 'HEALTHY'].includes(capitalized)) return { divClassName: style.deviceStatusActive, textClassName: style.cellTextDeviceStatusActive };
  if (capitalized === 'OFFLINE') return { divClassName: style.deviceStatusOffline, textClassName: style.cellTextDeviceStatusOffline };
  if (capitalized === 'ERROR') return { divClassName: style.deviceStatusError, textClassName: style.cellTextDeviceStatusError };
  return { divClassName: style.deviceStatusOffline, textClassName: style.cellTextDeviceStatusOffline };
};

function getCell<T extends CellPropT>({ value, colName, avatarUrl, modifiedDate, status }: PatientTableCellProp<T>) {
  const strValue = '' + value;
  const elapsedTime = modifiedDate ? getElapsedTimeDisplay(modifiedDate) : '';

  switch (colName) {
    case PatientTableColName.NAME:
      return (
        <>
          <div className={style.avatar}>{avatarUrl ? <img src={avatarUrl} /> : getInitials(strValue)}</div>
          <ValueTextElement value={strValue} />
        </>
      );
    case PatientTableColName.HEART_RATE:
    case PatientTableColName.BREATHING_RATE:
    case PatientTableColName.SLEEP_DURATION:
      return <VitalSign status={status} textValue={getDisplayValueOrDefault(value)} line2TextValue={elapsedTime} />;
    case PatientTableColName.DEVICE_STATUS: {
      const { divClassName, textClassName } = getDeviceStatusStyleClassName(strValue);
      const deviceStatus: string = properCase((value ?? '') as string);
      return (
        <div className={divClassName}>
          <ValueTextElement value={getDisplayValueOrDefault(deviceStatus)} cellTextClassName={textClassName} />
        </div>
      );
    }
    case PatientTableColName.PERSON_PRESENCE: {
      const patientPresence = getPatientPresence(strValue);
      return (
        <div>
          <div className={style.patientPresenceStatus}>
            <img className={style.dotImg} src={patientPresence === PatientPresenceType.NEAR_THE_DEVICE ? darkGreenDot : grayDot} />
            <ValueTextElement value={patientPresence} />
          </div>
          <div className={style.patientPresenceTime}>
            <ValueTextElement value={elapsedTime} cellTextClassName={style.timeText} />
          </div>
        </div>
      );
    }
    case PatientTableColName.SLEEP_EFFICENCY:
      return <ValueTextElement value={getDisplayValueOrDefault(value, '-', '%')} />;
    default:
      return <ValueTextElement value={getDisplayValueOrDefault(value)} />;
  }
}

const TableCell = <T extends CellPropT>(cellProps: PatientTableCellProp<T>) => {
  return (
    <td className={style.tableCell}>
      <div className={style.cellBox}>{getCell(cellProps)}</div>
    </td>
  );
};

export default TableCell;
