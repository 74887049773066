export interface AnalyticsState {
  readonly sessionId: string;
  readonly backgroundMs: number;
  readonly sessionStartMs: number;
  readonly sessionEndMs: number;
  readonly appEnterDate: string;
  readonly pageEnterDate: string;
  readonly pageLeftDate: string;
  readonly pageType: PageType;
}

export enum PageType {
  LOGIN = 'login',
  FORGOT_PASSWORD = 'forgot-password',
  FORGOT_PASSWORD_EMAIL = 'forgot-password/email',
  REGISTER = 'register',
  REGISTER_EMAIL = 'register/email',
  REGISTER_FULLNAME = 'register/fullname',
  REGISTER_PASSWORD = 'register/password',
  CREATE_ACCOUNT = 'create-account',
  CREATE_ACCOUNT_REGISTER = 'create-account/register',
  CREATE_ACCOUNT_PRE_INTERSTITIAL = 'create-account/pre-interstitial',
  CREATE_ACCOUNT_POST_INTERSTITIAL = 'create-account/post-interstitial',
  CREATE_ACCOUNT_PURCHASE = 'create-account/purchase',
  PAYMENT_CONFIRMATION = 'payment/confirmation',
  SENIOR_LIST = 'senior-list',
  SETTINGS = 'setting',
  TERMS_OF_SERVICE = 'settings/terms-of-service',
  ABOUT = 'settings/about',
  RESET_PASSWORD_BY_TOKEN = 'resetpw/bytoken',
  INVITE_BY_TOKEN = 'invite/bytoken',
  VERIFY_EMAIL_BY_TOKEN = 'verifyemail/bytoken',
  MAIN_MENU = 'main-menu',
  NEW_DASHBOARD = 'new-dashboard',
  KOKO_MAP = 'koko-map',
  ALL_USERS = 'all-users',
  FLAGGED_USERS = 'flagged-users',
  USER_DETAILS = 'user-details',
  USER_DETAILS_SLEEP_COACH_CHAT = 'user-details-sleep-coach-chat',
  DEMO_ACCESS_CODES = 'demo-access-codes',
  DEVICE_PROVISIONING = 'device-provisioning',
  FIRMWARE_VERSIONS = 'firmware-versions',
  DEVICE_OVERVIEW = 'device-overview',
  EMAIL_CONFIRMATION_TOKEN = 'email-confirmation-token',
  CONTENT_MANAGER = 'content-manager',
  CONTENT_ALL_TRACKS = 'content-all-tracks',
  CONTENT_ALL_CONTENT = 'content-all-content',
  CONTENT_TRACK = 'content-track',
  CONTENT_DEVICE_CONTENT_DETAILS = 'content-device-content-details',
  CONTENT_PAGE_DETAILS_UPDATE = 'content-page-details-update',
  CONTENT_PAGE_DETAILS_ADD = 'content-page-details-add',
  FLAG_DETAILS = 'flag-details',
  ALL_FLAGS = 'all-flags',
  NEW_SLEEP_COACH_CHATS = 'new-sleep-coach-chats',
  PERSON_PERMISSIONS = 'person-permissions',
  USERS = 'users',
  SLEEP_SCHEDULE_REVIEW = 'sleep-schedule-review',
  LINK_TO_MOBILE = 'link-to-mobile',
  DELETE_ACCOUNT = 'delete-account',
  DELETE_ACCOUNT_CONFIRM = 'delete-account-confirm',
  ALL_PATIENTS = 'all-patients',
  PATIENT_DASHBOARD = 'patient-dashboard',
  EDIT_PATIENT = 'edit-patient',
  NEW_PATIENT = 'new-patient',
  NEW_TEAM_MEMBER = 'new-team-member',
  ASSIGN_CARE_PLAN = 'assign-care-plan',
  NEW_ACTIVITY = 'new-activity',
  CARE_TEAM = 'care-team',
  CARE_GROUP = 'care-group',
  MANAGE_GROUP_PLANS = 'manage-group-plans',
  ADMIN_ORGANIZATIONS = 'admin-organizations',
  ADMIN_USERS = 'admin-users',
  ADMIN_PERMISSION_ROLES = 'admin-permission-roles'
}

export enum LogEventType {
  UNSPECIFIC = 'plain',
  PAGE_LEFT = 'page-left',
  APP_LEFT = 'app-left',
  SESSION_ENDED = 'session-ended'
}

export interface LogEvent {
  id: string;
  logEventType: LogEventType;
  clientLogDate: string;
  clientTimeZoneId: string;
  personId?: Nullable<string>;
  clientDeviceId?: Nullable<string>;
  sessionId: string;
  activityStartDate: string;
  activityEndDate: string;
  longValue: number;
  pageType?: Nullable<PageType>;
  clientAppType: string;
}

export enum FunnelUX {
  INTERSTITIAL_AFTER_CREATE_ACCOUNT = 'INTERSTITIAL_AFTER_CREATE_ACCOUNT',
  INTERSTITIAL_BEFORE_CREATE_ACCOUNT = 'INTERSTITIAL_BEFORE_CREATE_ACCOUNT',
  INTERSTITIAL_BEFORE_AFTER_CREATE_ACCOUNT = 'INTERSTITIAL_BEFORE_AFTER_CREATE_ACCOUNT',
  INTERSTITIAL_BEFORE_CHECKOUT = 'INTERSTITIAL_BEFORE_CHECKOUT'
}

export enum GaCustomEventType {
  ACCOUNT_CREATED = 'account-created',
  CREATE_ACCOUNT_PRE_INTERSTITIAL = 'interstitial-pre-next',
  CREATE_ACCOUNT_POST_INTERSTITIAL = 'interstitial-post-next',
  CREATE_ACCOUNT_PURCHASE_NEXT = 'purchase-next',
  PURCHASE = 'purchase',
  PASSWORD_CHANGED = 'password-changed',
  FORGOT_PASSWORD = 'forgot-password'
}

export enum FbPixelCustomEventType {
  ACCOUNT_CREATED = 'AccountCreated',
  PAGE_VIEW = 'PageView',
  PURCHASE = 'Purchase',
  PASSWORD_CHANGED = 'PasswordChanged',
  FORGOT_PASSWORD = 'ForgotPassword'
}

export enum SubmitAnalyticsType {
  ON_ENTER,
  ON_EXIT
}

export const findAnalyticsEvents = (pageType?: Nullable<PageType>): Nullable<AnalyticsEvents> => (pageType ? eventPageMapping.get(pageType) : undefined);

export interface AnalyticsEvents {
  gaEvent: GaCustomEventType;
  fbEvent?: Nullable<FbPixelCustomEventType>;
  submitAnalytics: SubmitAnalyticsType;
}

const eventPageMapping: Map<PageType, AnalyticsEvents> = new Map<PageType, AnalyticsEvents>([
  [PageType.CREATE_ACCOUNT_REGISTER, { gaEvent: GaCustomEventType.ACCOUNT_CREATED, fbEvent: FbPixelCustomEventType.ACCOUNT_CREATED, submitAnalytics: SubmitAnalyticsType.ON_EXIT }],
  [PageType.CREATE_ACCOUNT_PRE_INTERSTITIAL, { gaEvent: GaCustomEventType.CREATE_ACCOUNT_PRE_INTERSTITIAL, fbEvent: undefined, submitAnalytics: SubmitAnalyticsType.ON_EXIT }],
  [PageType.CREATE_ACCOUNT_POST_INTERSTITIAL, { gaEvent: GaCustomEventType.CREATE_ACCOUNT_POST_INTERSTITIAL, fbEvent: undefined, submitAnalytics: SubmitAnalyticsType.ON_EXIT }],
  [PageType.CREATE_ACCOUNT_PURCHASE, { gaEvent: GaCustomEventType.CREATE_ACCOUNT_PURCHASE_NEXT, fbEvent: undefined, submitAnalytics: SubmitAnalyticsType.ON_EXIT }],
  [PageType.PAYMENT_CONFIRMATION, { gaEvent: GaCustomEventType.PURCHASE, fbEvent: FbPixelCustomEventType.PURCHASE, submitAnalytics: SubmitAnalyticsType.ON_ENTER }],
  [
    PageType.RESET_PASSWORD_BY_TOKEN,
    { gaEvent: GaCustomEventType.PASSWORD_CHANGED, fbEvent: FbPixelCustomEventType.PASSWORD_CHANGED, submitAnalytics: SubmitAnalyticsType.ON_EXIT }
  ],
  [PageType.FORGOT_PASSWORD, { gaEvent: GaCustomEventType.FORGOT_PASSWORD, fbEvent: FbPixelCustomEventType.FORGOT_PASSWORD, submitAnalytics: SubmitAnalyticsType.ON_EXIT }]
]);

export interface PageEnteredPayload {
  page: PageType;
  date: string;
}

export interface PageLeftPayload {
  page: PageType;
  date: string;
}

export interface CreateNewSessionPayload {
  sessionId: string;
  sessionStartMs: number;
}
