import React from 'react';
import style from './ComingSoon.scss';
import { Text } from '../../../../components/Typography';
import clockCountDownIcon from '../../../../assets/images/clockCountDown.png';

export type ComingSoonProps = {
  title?: string;
  imgSrc?: string;
};

export const ComingSoon: React.FC<ComingSoonProps> = ({ title, imgSrc }) => {
  return (
    <div className={style.card}>
      <div>
        <img src={imgSrc} className={style.smallIcon} />
      </div>
      <div>
        <Text className={style.titleText}>{title}</Text>
        <div className={style.comingSoonContainer}>
          <img src={clockCountDownIcon} className={style.tinyIcon} />
          <Text className={style.comingSoonText}>Coming soon</Text>
        </div>
      </div>
    </div>
  );
};
