import React, { useState, useEffect } from 'react';
import { Chart } from 'react-google-charts';
import style from './Insights.scss';
import { Color } from '../../../../utils/color';
import { PersonVitalInsights, PersonVitalTimeFrame, PersonVitalType, VitalSignsStatus } from '../../../../redux/person/personTypes';
import { convertUTCToLocalizedDateTime } from '../../../../utils/time';
import moment from 'moment-timezone';
import { getFixedPointDisplay } from '../../../../utils/util';
import { getVitalStatusOutOfRangeText } from '../../PatientDashboardUtil';
import exclamationOctagon from '../../../../assets/images/exclamationOctagon.png';
import yellowOctagon from '../../../../assets/images/yellowOctagon.png';

export type InsightsChartProps = {
  timeZoneId: string;
  personVitalInsights?: PersonVitalInsights | null;
  vitalType: PersonVitalType;
  timeFrame: PersonVitalTimeFrame;
};

type InsightsChartDataType = Array<Array<Date | number | string>>;

const getDateFormat = (timeFrame?: PersonVitalTimeFrame) => {
  switch (timeFrame) {
    case PersonVitalTimeFrame.TWENTY_FOUR_HOURS:
      // return 'hh:mm a';
      return null;
    case PersonVitalTimeFrame.SEVEN_DAYS:
      return 'EEE, d MMM';
    case PersonVitalTimeFrame.THIRTY_DAYS:
      return 'd MMM';
    case PersonVitalTimeFrame.TWELVE_MONTHS:
      return 'd MMM yy';
    default:
      return null;
  }
};

const getXAsisGridlineCount = (timeFrame?: PersonVitalTimeFrame) => {
  switch (timeFrame) {
    case PersonVitalTimeFrame.TWENTY_FOUR_HOURS:
      return 12;
    case PersonVitalTimeFrame.SEVEN_DAYS:
      return 7;
    case PersonVitalTimeFrame.THIRTY_DAYS:
      return 15;
    case PersonVitalTimeFrame.TWELVE_MONTHS:
      return 12;
    default:
      return 12;
  }
};

const getMinTime = (timeFrame?: PersonVitalTimeFrame) => {
  const now = moment();
  switch (timeFrame) {
    case PersonVitalTimeFrame.SEVEN_DAYS:
      return now.subtract(7, 'days').toDate();
    case PersonVitalTimeFrame.THIRTY_DAYS:
      return now.subtract(30, 'days').toDate();
    case PersonVitalTimeFrame.TWELVE_MONTHS:
      return now.subtract(12, 'months').toDate();
    case PersonVitalTimeFrame.TWENTY_FOUR_HOURS:
    default:
      return now.subtract(1, 'days').toDate();
  }
};

const getDateForTimeZone = (dateStr: string, timeZoneId: string) => {
  const localDateTime = convertUTCToLocalizedDateTime(dateStr, timeZoneId);
  return new Date(localDateTime.year, localDateTime.month - 1, localDateTime.day, localDateTime.hour, localDateTime.minute, localDateTime.second);
};

const customTooltip = (start: Date, end: Date, min: number | string, max: number | string, timeFrame?: PersonVitalTimeFrame, status?: VitalSignsStatus) => {
  let dateTooltip = '';
  switch (timeFrame) {
    case PersonVitalTimeFrame.TWENTY_FOUR_HOURS:
      dateTooltip = `${moment(start).format('D MMM, hh:mm A')} - ${moment(end).format('hh:mm A')}`;
      break;
    case PersonVitalTimeFrame.SEVEN_DAYS: {
      dateTooltip = `${moment(start).format('ddd, D MMM')}`;
      break;
    }
    case PersonVitalTimeFrame.THIRTY_DAYS:
      dateTooltip = `${moment(start).format('D MMM YYYY')}`;
      break;
    case PersonVitalTimeFrame.TWELVE_MONTHS:
      dateTooltip = `${moment(start).format('MMM YYYY')}`;
      break;
    default:
      dateTooltip = `${moment(start).format('MMM YYYY')}`;
      break;
  }

  let statusTooltip = '';
  if (status === VitalSignsStatus.ABNORMAL || status === VitalSignsStatus.SUBOPTIMAL) {
    statusTooltip = `<div style="display: flex; align-items: center; height: 20px;">
      <image src="${status === VitalSignsStatus.ABNORMAL ? exclamationOctagon : yellowOctagon}" style="width: 14px; margin-right: 5px;" />
      <p style="color:${status === VitalSignsStatus.ABNORMAL ? Color.RED_700 : Color.YELLOW_600}; font-family:outfit; font-size:14px; line-height: 20px; margin: 5;">${getVitalStatusOutOfRangeText(status)}</p>
    </div>`;
  }

  return `<div style="padding: 5px; white-space: nowrap;">
    <p style="color:gray; font-family:outfit; font-size:14px; line-height: 20px; margin: 5;">Range</p>
    <p style="color:${Color.DARK_BLUE_NEW}; font-family:outfit; font-size:30px; line-height: 38px; margin: 5;">${min} - ${max} bpm</p>
    <p style="color:gray; font-family:outfit; font-size:14px; line-height: 20px; margin: 5;">${dateTooltip}</p>
    ${statusTooltip}
    </div>`;
};

const getChartColor = (personVitalInsights?: Nullable<PersonVitalInsights>) => {
  const { patientMetrics } = personVitalInsights ?? {};

  if (patientMetrics?.minRateStatus === 'ABNORMAL' || patientMetrics?.maxRateStatus === 'ABNORMAL') {
    return Color.PINK_600;
  }
  if (patientMetrics?.minRateStatus === 'SUBOPTIMAL' || patientMetrics?.maxRateStatus === 'SUBOPTIMAL') {
    return Color.YELLOW_600;
  }
  return Color.DARK_BLUE_500;
};

const getYMinMaxValue = (vitalType: PersonVitalType) => {
  switch (vitalType) {
    case PersonVitalType.HEART_RATE:
      return { yMinValue: 30, yMaxValue: 140 };
    case PersonVitalType.BREATHING_RATE:
      return { yMinValue: 0, yMaxValue: 60 };
    default:
      return { yMinValue: 0, yMaxValue: 140 };
  }
};

export const InsightsChart: React.FC<InsightsChartProps> = ({ timeZoneId, personVitalInsights, vitalType, timeFrame }) => {
  const [chartData, setChartData] = useState<any[]>([]);
  const { yMinValue, yMaxValue } = getYMinMaxValue(vitalType);
  const xMinValue = getMinTime(timeFrame);
  const xMaxValue = new Date();
  const chartColor = getChartColor(personVitalInsights);
  const xAsisGridlineCount = getXAsisGridlineCount(timeFrame);
  const title = vitalType === PersonVitalType.HEART_RATE ? 'Heart rate(bpm)' : 'Breathing rate(bpm)';

  const getChartData = (personVitalInsights?: PersonVitalInsights | null): InsightsChartDataType => {
    if (!personVitalInsights) {
      return [[]];
    }
    const aggregatedProcessedVitalSigns = personVitalInsights.listOfAggregatedProcessedVitalSigns;
    return aggregatedProcessedVitalSigns
      .map((vitalSign) => {
        const min = vitalType === PersonVitalType.HEART_RATE ? vitalSign.minHeartRate : vitalSign.minBreathingRate;
        const max = vitalType === PersonVitalType.HEART_RATE ? vitalSign.maxHeartRate : vitalSign.maxBreathingRate;
        const status = vitalType === PersonVitalType.HEART_RATE ? vitalSign.heartRateStatus : vitalSign.breathingRateStatus;
        const start = getDateForTimeZone(vitalSign.startTime, timeZoneId);
        const end = getDateForTimeZone(vitalSign.endTime, timeZoneId);

        if (typeof min === 'number' && typeof max === 'number' && start && end) {
          const minVal = getFixedPointDisplay(min);
          const maxVal = getFixedPointDisplay(max);

          return [start, minVal, minVal, maxVal, maxVal, customTooltip(start, end, minVal, maxVal, timeFrame, status)];
        }
        return [];
      })
      .filter((data) => data.length > 0);
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = getChartData(personVitalInsights);
      setChartData([['Date', 'Low', 'Open', 'Close', 'High', { type: 'string', role: 'tooltip', p: { html: true } }], ...data]);
    };

    fetchData();
  }, [personVitalInsights]);

  const options = {
    legend: 'none',
    title: title,
    titleTextStyle: {
      color: chartColor
    },
    tooltip: { isHtml: true },
    bar: { groupWidth: 13 },
    fontName: 'Outfit',
    hAxis: {
      title: '',
      textStyle: {
        color: Color.GRAY,
        fontSize: 12
      },
      viewWindowMode: 'explicit',
      minValue: xMinValue,
      maxValue: xMaxValue,
      viewWindow: {
        min: xMinValue,
        max: xMaxValue
      },
      format: getDateFormat(timeFrame),
      gridlines: {
        // hides vertical gridlines, which are drawn vertically
        // color: 'white'
        count: xAsisGridlineCount
      },
      minorGridlines: {
        count: 0
      }
    },
    vAxis: {
      title: '',
      textStyle: {
        color: chartColor,
        fontSize: 12
      },
      minValue: yMinValue,
      maxValue: yMaxValue,
      viewWindow: {
        min: yMinValue,
        max: yMaxValue
      },
      gridlines: {
        color: Color.DARK_GREEN
      },
      minorGridlines: {
        color: Color.GRAY_100
      }
    },
    colors: [chartColor],
    chartArea: {
      width: '85%',
      height: '85%'
    }
  };
  return (
    <>
      {chartData.length > 1 ? (
        <Chart width={'100%'} height={'400px'} chartType="CandlestickChart" loader={<div>Loading Chart</div>} data={chartData} options={options} />
      ) : (
        <div className={style.noChartDataContainer}>No data to display</div>
      )}
    </>
  );
};
