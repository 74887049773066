import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import plusIcon from '../../assets/images/plus.png';
import { Button } from '../../components/Button/Button';
import { Display } from '../../components/Typography';
import { BreadCrumbData } from '../../redux/common/types';
import { appendBreadcrumbAction } from '../../redux/oauth/oauthActions';
import { getPersonByIdSelector, getPersonVitalsByPersonIdSelector, hasPatientCreatePermissionSelector } from '../../redux/person/personSelector';
import { ReduxState } from '../../redux/types';
import style from './AllPatients.scss';
import PatientTable from './components/PatientTable/PatientTable';
import { PatientTableData } from './components/PatientTable/PatientTableTypes';
import { getPersonOverviewThunk, getPersonVitalsThunk, setSelectedPatientIdThunk } from '../../redux/person/personThunks';
import { getAuthPersonSelector } from '../../redux/oauth/oauthSelectors';
import NavTopBarContentWrapper from '../../components/NavTopBarContentWrapper/NavTopBarContentWrapper';

const FETCH_PATIENT_STAT_INTERVAL = 30000;

type PropsFromRedux = ConnectedProps<typeof connectRedux>;

type Props = PropsFromRedux & {};

const AllPatients: React.FC<Props> = ({
  hasPatientCreatePermission,
  personVitalsByPersonIdSelector,
  authPersonSelector,
  personByIdSelector,
  appendBreadCrumbs,
  getPersonOverview,
  getPersonVitals,
  setSelectedPatientId
}) => {
  const [count, setCount] = useState(0);
  const [patientTableData, setPatientTableData] = useState<PatientTableData[]>([]);
  const organizationId = authPersonSelector?.organizationId ?? '';

  const navigate = useNavigate();

  useEffect(() => {
    const fetchMeasurements = async () => {
      await getPersonOverview({});
      await getPersonVitals(organizationId);
    };

    fetchMeasurements();
  }, [count]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount(count + 1);
    }, FETCH_PATIENT_STAT_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const getPatientTableData = (): PatientTableData[] => {
      const tableData: PatientTableData[] = [];
      for (const personId of Object.keys(personVitalsByPersonIdSelector)) {
        const personVitals = personVitalsByPersonIdSelector[personId];
        if (!personVitals) {
          continue;
        }
        const person = personByIdSelector[personId];
        if (!person) {
          continue;
        }
        const personFullName = person?.fullName;

        const tableRow: PatientTableData = {
          ...personVitals,
          name: personFullName ?? '',
          avatar: ''
        };
        tableData.push(tableRow);
      }
      return tableData;
    };

    setPatientTableData(getPatientTableData());
  }, [personVitalsByPersonIdSelector]);

  const goToPatientDashboard = React.useCallback(
    (personId: string) => {
      setSelectedPatientId(personId);
      navigate(`/patients/${personId}`);
    },
    [navigate]
  );

  const goToNewPatientPage = React.useCallback(
    (e) => {
      navigate('/patients/newPatient');
    },
    [navigate]
  );

  return (
    <NavTopBarContentWrapper
      headerText={() => {
        return (
          <div className={style.headerContainer}>
            <Display variant="sm">Patients</Display>
            {hasPatientCreatePermission && (
              <Button className={style.newPatient} variant="contained" onClick={goToNewPatientPage} startIcon={<img src={plusIcon} className={style.plusIcon} />}>
                Create new
              </Button>
            )}
          </div>
        );
      }}
    >
      <PatientTable patientTableData={patientTableData} onPressTableRow={goToPatientDashboard} />
    </NavTopBarContentWrapper>
  );
};

const connectRedux = connect(
  (state: ReduxState) => ({
    hasPatientCreatePermission: hasPatientCreatePermissionSelector(state),
    authPersonSelector: getAuthPersonSelector(state),
    personVitalsByPersonIdSelector: getPersonVitalsByPersonIdSelector(state),
    personByIdSelector: getPersonByIdSelector(state)
  }),
  {
    appendBreadCrumbs: (breadCrumbData: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[], isOldApp?: boolean) =>
      appendBreadcrumbAction({ breadCrumbData, defaultCrumbHistory, isOldApp }),
    getPersonOverview: getPersonOverviewThunk,
    getPersonVitals: getPersonVitalsThunk,
    setSelectedPatientId: setSelectedPatientIdThunk
  }
);

export default compose(connectRedux)(AllPatients);
