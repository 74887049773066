import React from 'react';
import style from './VitalSign.scss';
import { VitalSignsStatus } from '../../redux/person/personTypes';
import exclamationOctagon from '../../assets/images/exclamationOctagon.png';
import yellowOctagon from '../../assets/images/yellowOctagon.png';
import { Text, TextVariant } from '../Typography';
import classNames from 'classnames';

export type VitalSignStatusProps = {
  status?: VitalSignsStatus;
  showBg?: boolean;
  textValue?: string | number;
  textVariant?: TextVariant;
  textClassName?: string;
  line2TextValue?: string | number;
  line2TextVariant?: TextVariant;
  line2TextClassName?: string;
};

export const VitalSign: React.FC<VitalSignStatusProps> = ({
  status,
  showBg = false,
  textValue,
  textVariant = 'sm',
  textClassName = style.valueText,
  line2TextValue = '',
  line2TextVariant = 'sm',
  line2TextClassName = style.line2Text
}) => {
  return (
    <div>
      <div className={style.iconTextContainer}>
        <div
          className={classNames(
            style.imgContainer,
            { [style.redBg]: showBg && status === VitalSignsStatus.ABNORMAL },
            { [style.yellowBg]: showBg && status === VitalSignsStatus.SUBOPTIMAL },
            { [style.noBg]: !showBg }
          )}
        >
          {status === VitalSignsStatus.ABNORMAL && <img className={style.octagonImg} src={exclamationOctagon} />}
          {status === VitalSignsStatus.SUBOPTIMAL && <img className={style.octagonImg} src={yellowOctagon} />}
        </div>
        {textValue && (
          <Text variant={textVariant} className={textClassName}>
            {textValue}
          </Text>
        )}
      </div>
      {line2TextValue && (
        <div className={style.line2Container}>
          <Text variant={line2TextVariant} className={line2TextClassName}>
            {line2TextValue}
          </Text>
        </div>
      )}
    </div>
  );
};
