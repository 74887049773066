import React from 'react';
import { Grid } from '@mui/material';
import { Text, TextVariant } from '../../../../components/Typography';
import style from './Insights.scss';
import { VitalSignsStatus } from '../../../../redux/person/personTypes';
import { VitalSign } from '../../../../components/VitalSign/VitalSign';

export type GridRowProps = {
  label: string;
  metricValue: number | string;
  metricStatus?: VitalSignsStatus;
  normalControlValue: number | string;
  abnormalControlValue: number | string;
  suboptimalControlValue: number | string;
  textVariant?: TextVariant;
  rowHeaderClassName?: string;
  rowValueTextClassName?: string;
  rowValueContainerClassName?: string;
  rowClassName?: string;
};

export const GridRow: React.FC<GridRowProps> = ({
  label,
  metricValue,
  metricStatus,
  normalControlValue,
  abnormalControlValue,
  suboptimalControlValue,
  textVariant = 'md',
  rowHeaderClassName = style.defaultGrayText,
  rowValueTextClassName = style.defaultGrayText,
  rowValueContainerClassName = style.metricsValueContainer,
  rowClassName = style.metricsGridRow
}) => {
  return (
    <Grid container className={rowClassName}>
      <Grid item xs={4}>
        <Text variant={textVariant} className={rowHeaderClassName}>
          {label}
        </Text>
      </Grid>
      {[metricValue, normalControlValue].map((controlValue, index) => (
        <Grid item xs={4} key={index}>
          <div className={rowValueContainerClassName}>
            <VitalSign status={index === 0 ? metricStatus : undefined} textValue={controlValue} textVariant={textVariant} textClassName={rowValueTextClassName} />
          </div>
        </Grid>
      ))}
    </Grid>
  );
};
