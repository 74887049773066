import React from 'react';
import style from './PatientTable.scss';
import TableCell from './TableCell';
import TableHeader from './TableHeader';
import { PatientTableColName, PatientTableProps } from './PatientTableTypes';

const Table: React.FC<PatientTableProps> = React.memo(({ patientTableData, onPressTableRow }) => {
  return (
    <table className={style.patientsTable}>
      <thead>
        <tr className={style.tableRow}>
          <TableHeader>Name</TableHeader>
          <TableHeader>Heart rate (bpm)</TableHeader>
          <TableHeader>Breathing rate (bpm)</TableHeader>
          <TableHeader>Sleep duration</TableHeader>
          <TableHeader>Sleep Efficiency</TableHeader>
          <TableHeader>Patient presence</TableHeader>
          <TableHeader>Device status</TableHeader>
        </tr>
      </thead>
      <tbody>
        {patientTableData.map((patientData) => {
          const {
            personId,
            name,
            avatar,
            heartRate,
            heartRateStatus,
            heartRateLastReading,
            breathingRate,
            breathingRateStatus,
            breathingRateLastReading,
            sleepDurations,
            sleepEfficiency,
            personPresence,
            personPresenceLastReading,
            deviceStatus
          } = patientData;
          const handleRowClick = () => {
            onPressTableRow(personId);
          };
          return (
            <tr key={personId} onClick={handleRowClick} className={style.tableRow}>
              <TableCell colName={PatientTableColName.NAME} value={name} avatarUrl={avatar} />
              <TableCell colName={PatientTableColName.HEART_RATE} value={heartRate} modifiedDate={heartRateLastReading} status={heartRateStatus} />
              <TableCell colName={PatientTableColName.BREATHING_RATE} value={breathingRate} modifiedDate={breathingRateLastReading} status={breathingRateStatus} />
              <TableCell colName={PatientTableColName.SLEEP_DURATION} value={sleepDurations} />
              <TableCell colName={PatientTableColName.SLEEP_EFFICENCY} value={sleepEfficiency} />
              <TableCell colName={PatientTableColName.PERSON_PRESENCE} value={personPresence} modifiedDate={personPresenceLastReading} />
              <TableCell colName={PatientTableColName.DEVICE_STATUS} value={deviceStatus} />
            </tr>
          );
        })}
      </tbody>
    </table>
  );
});

export default Table;
