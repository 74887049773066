import Config from '../../config/config';

const isProduction = Config.getDeploymentEnv() !== 'local';

export const IMAGE_PREFIX = isProduction ? '/assets/images' : '/assets-dev/images';

// Images over 1kb shoyuld be loaded from the server
export const LOADING_GIF_URL = `${IMAGE_PREFIX}/loading.gif`;
export const DEVICE_IMAGE_URL = `${IMAGE_PREFIX}/device.png`;
export const NOBACK_MULTICOLOR_IMAGE_URL = `${IMAGE_PREFIX}/Noback_multicolor.png`;
export const FULL_SLEEP_MOBILE_IMAGE_URL = `${IMAGE_PREFIX}/fullsleepmobile.png`;
export const CAIRNS_HEALTH_IMAGE_URL = `${IMAGE_PREFIX}/cairnsHealth.png`;
export const APPLE_STORE_IMAGE_URL = `${IMAGE_PREFIX}/appleStore.png`;
export const GOOGLE_PLAY_IMAGE_URL = `${IMAGE_PREFIX}/googlePlay.png`;
export const FAIL_IMAGE_URL = `${IMAGE_PREFIX}/fail.png`;
export const SUCCESS_IMAGE_URL = `${IMAGE_PREFIX}/success.png`;
export const LUNA_IMAGE_URL = `${IMAGE_PREFIX}/luna.png`;
export const GEAR_IMAGE_URL = `${IMAGE_PREFIX}/gear.png`;
export const BEDTIME_IMAGE_URL = `${IMAGE_PREFIX}/bedtime.png`;
export const WAKETIME_IMAGE_URL = `${IMAGE_PREFIX}/waketime.png`;
export const USERS_IMAGE_URL = `${IMAGE_PREFIX}/users.png`;
export const HAND_HEART_IMAGE_URL = `${IMAGE_PREFIX}/handHeart.png`;
export const STETHOSCOPE_IMAGE_URL = `${IMAGE_PREFIX}/stethoscope.png`;
export const OPTION_SET_ICON_IMAGE_URL = `${IMAGE_PREFIX}/option-set-icon.png`;
export const NOTEPAD_IMAGE_URL = `${IMAGE_PREFIX}/notepad.png`;
export const CAIRNS_LOGO_IMAGE_URL = `${IMAGE_PREFIX}/logo.png`;
