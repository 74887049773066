import React, { useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { compose } from 'redux';
import { ReduxState } from 'redux/types';
import {
  CarePlanAutocomplete,
  CarePlanAutocompletePaper,
  CarePlanAutocompletePopper,
  CarePlanAutocompleteTextField as CarePlanTextField
} from '../../components/CarePlanAutocomplete/CarePlanAutocomplete';
import { Text } from '../../components/Typography/Text';
import { TextField } from '../../components/TextField';
import { Button } from '../../components/Button/Button';
import {
  getCareTeamByIdSelector,
  getCareTeamPersonsSelector,
  getErrorSelector,
  getIsLoadingSelector,
  getPersonCareTeamByIdSelector,
  getSuccessSelector
} from '../../redux/clinical/clinicalSelectors';
import { addNewCareTeamThunk, addPersonToCareTeamThunk, getCareTeamPersonsThunk, getCareTeamsThunk, removePersonFromCareTeamThunk } from '../../redux/clinical/clinicalThunks';
import { CareTeam } from '../../redux/clinical/clinicalTypes';
import {
  getPermissionRoleByIdSelector,
  getPermissionRoleTypeByIdSelector,
  getPermissionRoleTypeNameByPersonIdSelector,
  getPersonByIdSelector,
  getPersonPermissionRoleByIdSelector,
  getPersonPermissionRoleIdsByPersonIdSelector
} from '../../redux/person/personSelector';
import { Person } from '../../redux/person/personTypes';
import style from './CareTeam.scss';
import NavTopBarContentWrapper from '../../components/NavTopBarContentWrapper/NavTopBarContentWrapper';

type CareTeamSelect = {
  id: string;
  name: string;
};

const ADD_NEW_CARE_TEAM_ID = 'aad_new_care_team';

type PropsFromRedux = ConnectedProps<typeof connectRedux>;
type Props = PropsFromRedux & {};

const CareTeam: React.FC<Props> = ({
  getCareTeamPersons,
  getCareTeams,
  addNewCareTeam,
  addPersonToCareTeam,
  removePersonFromCareTeam,
  personPermissionRoleById,
  permissionRoleById,
  permissionRoleTypeById,
  personPermissionRoleIdsByPersonId,
  personCareTeamById,
  personById,
  careTeamPersons,
  careTeamById,
  isLoading,
  errors,
  success
}) => {
  const [selectedCareTeam, setSelectedCareTeam] = React.useState<CareTeamSelect | null>(null);
  const [selectedNewTeamMember, setSelectedNewTeamMember] = React.useState<Person | null>(null);
  const [newCareTeamName, setNewCareTeamName] = React.useState<string>('');
  const [apiError, setApiError] = React.useState('');
  const [successMessage, setSuccessMessage] = React.useState('');

  const showNewCareTeamForm = selectedCareTeam?.id === ADD_NEW_CARE_TEAM_ID;
  const isCreateNewTeamButtonDisabled = !newCareTeamName || !selectedCareTeam;
  const isCreateNewPatientButtonDisabled = !selectedNewTeamMember || !selectedCareTeam;

  const careTeams: CareTeamSelect[] = [
    { id: '', name: '--- Select Care Team ---' },
    ...Object.values(careTeamById).map((careTeam) => {
      return { id: careTeam.id, name: careTeam.name };
    }),
    { id: ADD_NEW_CARE_TEAM_ID, name: '+ Add New Care Team' }
  ];

  const careTeamId = selectedCareTeam?.id;
  const careTeam = careTeamId ? careTeamById[careTeamId] : null;

  const careTeamCandidates: Person[] = [];
  for (const personId of careTeamPersons) {
    if (careTeam && careTeam.memberPersonCareTeamIds) {
      let found = false;
      for (const personCareTeamId of careTeam.memberPersonCareTeamIds) {
        if (personCareTeamById[personCareTeamId]?.personId === personId) {
          found = true;
          break;
        }
      }
      if (!found && personId && personById) {
        careTeamCandidates.push(personById[personId]);
      }
    } else {
      careTeamCandidates.push(personById[personId]);
    }
  }

  useEffect(() => {
    getCareTeamPersons();
    getCareTeams();
  }, []);

  const handleCreateFormSubmit = async (event) => {
    event.preventDefault();
    if (newCareTeamName) {
      try {
        setApiError('');
        setSuccessMessage('');
        await addNewCareTeam(newCareTeamName);
        setSuccessMessage('Care team created successfully.');
        setNewCareTeamName('');
      } catch (err) {
        setApiError('Something went wrong. Please try again.');
      }
    }
  };

  const onCareTeamSelect = (event, value) => {
    setSuccessMessage('');
    setApiError('');
    setSelectedCareTeam(value);
  };

  const onNewTeamMemberSelect = (event, value) => {
    setSuccessMessage('');
    setApiError('');
    setSelectedNewTeamMember(value);
  };

  const onRemoveCareTeamMember = (careTeam: CareTeam, personId: string) => {
    const personCareTeamId = careTeam.memberPersonCareTeamIds?.find((id) => personCareTeamById[id]?.personId === personId);
    if (personCareTeamId) {
      removePersonFromCareTeam(careTeam.id, personCareTeamId);
    }
  };

  const handleAddNewPatient = async () => {
    if (careTeam?.id && selectedNewTeamMember?.id) {
      try {
        setApiError('');
        setSuccessMessage('');
        await addPersonToCareTeam(careTeam?.id, selectedNewTeamMember?.id);
        setSuccessMessage('Care team created successfully.');
        setSelectedNewTeamMember(null);
      } catch (err) {
        setApiError('Something went wrong. Please try again.');
      }
    }
  };

  const getPeronSelectOption = (person: Person) => {
    const title = getPermissionRoleTypeNameByPersonIdSelector(person.id, personPermissionRoleIdsByPersonId, personPermissionRoleById, permissionRoleById, permissionRoleTypeById);
    return person.givenNames + ' ' + person.familyName + ' (' + title + ')';
  };

  return (
    <NavTopBarContentWrapper headerText="Care team">
      <div className={style.mainContainer}>
        <div className={style.subContainer}>
          <div>
            <Text variant="sm" className={style.labelSelect}>
              Select care team
            </Text>
            <CarePlanAutocomplete
              options={careTeams}
              value={selectedCareTeam || null} // Ensure value is not undefined
              onChange={onCareTeamSelect}
              getOptionLabel={(option) => (option as CareTeamSelect).name || ''}
              isOptionEqualToValue={(option, value) => (option as CareTeamSelect).id === (value as CareTeamSelect).id}
              PaperComponent={CarePlanAutocompletePaper}
              PopperComponent={CarePlanAutocompletePopper}
              renderInput={(params) => <CarePlanTextField name="careplan" {...params} placeholder="Select" />}
              renderOption={(props, option, state) => (
                <li {...props} key={(option as CareTeamSelect).id}>
                  <div>{(option as CareTeamSelect).name}</div>
                </li>
              )}
            />
          </div>
          {showNewCareTeamForm && (
            <form onSubmit={handleCreateFormSubmit} className={style.mainContainer}>
              <Text variant="xl">New care team</Text>
              {/* CARE TEAM NAME */}
              <div className={style.subContainer}>
                <div className={style.formRow}>
                  <TextField
                    name="careTeamName"
                    value={newCareTeamName}
                    onChange={(e) => setNewCareTeamName(e.target.value)}
                    label="Care team name"
                    placeholder="Care team name"
                    autoComplete="new-password"
                  />
                </div>
              </div>
              <Button type="submit" variant="contained" disabled={isCreateNewTeamButtonDisabled} className={style.button}>
                Create Care team
              </Button>
            </form>
          )}
          {careTeam && (
            <>
              <Text variant="xl">Team members</Text>
              <div className={style.subContainer}>
                {careTeam?.memberPersonCareTeamIds?.map((personCareTeamId) => {
                  const person = personById[personCareTeamById[personCareTeamId].personId];
                  return (
                    <div key={person.id} className={style.memberItem}>
                      <Text variant="md">{getPeronSelectOption(person)}</Text>
                      <Button
                        variant="contained"
                        onClick={(e) => {
                          e.preventDefault();
                          onRemoveCareTeamMember(careTeam, person.id);
                        }}
                      >
                        Remove
                      </Button>
                    </div>
                  );
                })}
              </div>
              <Text variant="xl">Add new member</Text>
              <div>
                <Text variant="sm" className={style.labelSelect}>
                  Add new team member
                </Text>
                <CarePlanAutocomplete
                  options={careTeamCandidates}
                  value={selectedNewTeamMember || null} // Ensure value is not undefined
                  onChange={onNewTeamMemberSelect}
                  getOptionLabel={(option) => getPeronSelectOption(option as Person)}
                  isOptionEqualToValue={(option, value) => (option as Person).id === (value as Person).id}
                  PaperComponent={CarePlanAutocompletePaper}
                  PopperComponent={CarePlanAutocompletePopper}
                  renderInput={(params) => <CarePlanTextField {...params} placeholder="Select" />}
                  renderOption={(props, option, state) => (
                    <li {...props} key={(option as Person).id}>
                      <div>{getPeronSelectOption(option as Person)}</div>
                    </li>
                  )}
                />
              </div>
              <Button variant="contained" className={style.button} disabled={isCreateNewPatientButtonDisabled} onClick={handleAddNewPatient}>
                Add new member
              </Button>
            </>
          )}
        </div>
        {!!apiError && (
          <Text variant="sm" className={style.apiError}>
            {apiError}
          </Text>
        )}
        {!!successMessage && (
          <Text variant="sm" className={style.successMessage}>
            {successMessage}
          </Text>
        )}
      </div>
    </NavTopBarContentWrapper>
  );
};

const connectRedux = connect(
  (state: ReduxState) => ({
    personById: getPersonByIdSelector(state),
    careTeamPersons: getCareTeamPersonsSelector(state),
    personCareTeamById: getPersonCareTeamByIdSelector(state),
    personPermissionRoleById: getPersonPermissionRoleByIdSelector(state),
    permissionRoleById: getPermissionRoleByIdSelector(state),
    permissionRoleTypeById: getPermissionRoleTypeByIdSelector(state),
    personPermissionRoleIdsByPersonId: getPersonPermissionRoleIdsByPersonIdSelector(state),
    careTeamById: getCareTeamByIdSelector(state),
    isLoading: getIsLoadingSelector(state),
    errors: getErrorSelector(state),
    success: getSuccessSelector(state)
  }),
  (dispatch: Function) => ({
    getCareTeamPersons: () => {
      dispatch(getCareTeamPersonsThunk());
    },
    getCareTeams: () => {
      dispatch(getCareTeamsThunk());
    },
    addNewCareTeam: (careTeamName: string): CareTeam => {
      return dispatch(addNewCareTeamThunk(careTeamName));
    },
    addPersonToCareTeam: (careTeamId: string, personId: string) => {
      dispatch(addPersonToCareTeamThunk(careTeamId, personId));
    },
    removePersonFromCareTeam: (careTeamId: string, personId: string) => {
      dispatch(removePersonFromCareTeamThunk(careTeamId, personId));
    }
  })
);

export default compose(connectRedux)(CareTeam);
