import React, { useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, SxProps, Theme, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const topRightSx: SxProps<Theme> = {
  '& .MuiDialog-paper': {
    top: '10px',
    right: '10px',
    position: 'absolute'
  }
};

const closeIconSx: SxProps<Theme> = {
  position: 'absolute',
  right: 8,
  top: 8,
  color: (theme) => theme.palette.grey[500]
};

export type CloseIconDialogProps = {
  open: boolean;
  onClose: () => void;
  titleText?: string;
  getContent?: () => React.ReactNode;
};

export const CloseIconDialog: React.FC<CloseIconDialogProps> = ({ open, onClose, titleText = '', getContent }) => {
  const [showDialog, setShowDialog] = React.useState(open);

  useEffect(() => {
    setShowDialog(open);
  }, [open]);

  const closeDialog = () => {
    setShowDialog(false);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} sx={topRightSx}>
      <DialogTitle>{titleText}</DialogTitle>
      <IconButton aria-label="close" onClick={closeDialog} sx={closeIconSx}>
        <CloseIcon />
      </IconButton>
      <DialogContent>{getContent?.()}</DialogContent>
    </Dialog>
  );
};
