import React from 'react';
import NavTopBar from '../../navigation/NavTopBar/NavTopBar';
import style from './NavTopBarContentWrapper.scss';
import { Display } from '../Typography';
import classNames from 'classnames';
import { ThemeProvider } from '@mui/material/styles';
import { mvpTheme } from '../../utils/styles';

interface Props {
  headerText?: string | Function;
  headerClassName?: string;
  contentClassName?: string;
  children: React.ReactNode;
}

const NavTopBarContentWrapper: React.FC<Props> = ({ children, headerText, headerClassName, contentClassName }) => {
  return (
    <ThemeProvider theme={mvpTheme}>
      <div className={style.topContainer}>
        <div className={style.container}>
          <NavTopBar />
          {headerText && (
            <div className={classNames(style.header, headerClassName)}>
              <div className={style.headerItem}>{typeof headerText === 'function' ? headerText() : <Display variant="sm">{headerText}</Display>}</div>
            </div>
          )}
          <div className={classNames(style.content, contentClassName)}>{children}</div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default NavTopBarContentWrapper;
